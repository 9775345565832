<template>
    <main>
        <div class="modal fade" id="modalUser" tabindex="-1" aria-hidden="true" aria-labelledby="modalTitle"
            data-bs-backdrop="status">
            <div class="modal-dialog modal-xl modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalTitle">Datos de Usuario</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!-- Información Esencial -->
                        <div
                            style="display: flex; flex-direction: row; background-color: #f5f5f5; padding: 10px;justify-content: left; align-items: center;">
                            <div style="display: flex; flex-direction: column; width: 90%;">
                                <div
                                    style="display: flex; flex-direction: row; justify-content: left; align-items: center;">
                                    <p
                                        style="font-size: 20px; margin-left: 3%; font-weight: bold; font-family: Poppins;">
                                        {{ viewDataArray.name }}
                                        {{ viewDataArray.lastname }}
                                        {{ viewDataArray.secondlastname }}
                                    </p>
                                </div>
                                <div class="d-flex flex-row" style="margin-left: 3%;">
                                    <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; width: 37%;"
                                        v-show="viewDataArray.status === 'preuniversitario'">
                                        <span v-show="viewDataArray.status === 'preuniversitario'"
                                            style="font-size: 15px; font-weight: bold; font-family: Poppins;">
                                            Estudiante de Bachillerato o Preparatoria
                                        </span>
                                    </div>
                                    <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; width: 20%;"
                                        v-show="viewDataArray.status === 'universitario' || viewDataArray.status === 'exuniversitario'">
                                        <span v-show="viewDataArray.status === 'universitario'"
                                            style="font-size: 15px; font-weight: bold; font-family: Poppins;">
                                            Estudiante Universitario
                                        </span>
                                        <span v-show="viewDataArray.status === 'exuniversitario'"
                                            style="font-size: 15px; font-weight: bold; font-family: Poppins;">
                                            Profesionista Universitario
                                        </span>
                                    </div>
                                    <div v-if="viewDataArray.date"
                                        style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; width: 28%;">
                                        <span v-show="viewDataArray.date">|&nbsp;&nbsp;</span>
                                        <span style="font-size: 15px; font-weight: bold;">
                                            Edad: {{ getEdad(viewDataArray.date) }} años
                                        </span>
                                    </div>
                                    <div style="width: 20%;" v-if="viewDataArray.date">
                                    </div>
                                    <div style="width: 55%;" v-if="!viewDataArray.date">
                                    </div>
                                    <div
                                        style="display:flex; justify-content: flex-end; align-items: center; width: 10%;">
                                        <div v-if="viewDataArray.linkedin">
                                            <a class="J-btn auto" title="LinkedIn" target="_blank"
                                                v-on:click="openLinkedin(viewDataArray.linkedin)">
                                                <box-icon name='linkedin' type='logo' color="#0e64ed"></box-icon>
                                            </a>
                                        </div>
                                    </div>
                                    <div style="display:flex; justify-content: flex-start; align-items: center; width: 8%;"
                                        v-if="viewDataArray.filecv">
                                        <a class="J-btn auto" title="CV" target="_blank"
                                            :href="viewDataArray.filecv.link">
                                            <box-icon name='file-pdf' type="solid" color="#0e64ed"></box-icon>
                                        </a>
                                    </div>
                                    <div style="display:flex; justify-content: flex-start; align-items: center; width: 10%;"
                                        v-if="viewDataArray.filecomprobantes">
                                        <a class="J-btn auto" title="Comprobante" target="_blank"
                                            :href="viewDataArray.filecomprobantes.link">
                                            <box-icon name='clipboard' color="#0e64ed"></box-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Nueva Navegación -->
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                    data-bs-target="#home-tab-pane" type="button" role="tab"
                                    aria-controls="home-tab-pane" aria-selected="true">Datos Personales</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                    data-bs-target="#profile-tab-pane" type="button" role="tab"
                                    aria-controls="profile-tab-pane" aria-selected="false">Información Escolar</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="contact-tab" data-bs-toggle="tab"
                                    data-bs-target="#contact-tab-pane" type="button" role="tab"
                                    aria-controls="contact-tab-pane" aria-selected="false">Idiomas</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="disabled-tab" data-bs-toggle="tab"
                                    data-bs-target="#disabled-tab-pane" type="button" role="tab"
                                    aria-controls="disabled-tab-pane" aria-selected="false">Hobbies</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="experiencia-tab" data-bs-toggle="tab"
                                    data-bs-target="#experiencia-tab-pane" type="button" role="tab"
                                    aria-controls="experiencia-tab-pane" aria-selected="false">Experiencia</button>
                            </li>
                        </ul>
                        <!-- Nueva Vista de Datos -->
                        <div class="tab-content" id="myTabContent">
                            <!-- Información Personal -->
                            <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel"
                                aria-labelledby="home-tab" tabindex="0">
                                <div class="container-postulante flex">
                                    <div style="display: flex; justify-content: left; align-items: center;">
                                        <div class="d-flex flex-row mt-2">
                                            <div class="row">
                                                <div class="text-justify m-2">
                                                    <b>Nombre:</b>
                                                    {{ viewDataArray.name }}
                                                    {{ viewDataArray.lastname }}
                                                    {{ viewDataArray.secondlastname }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="text-justify m-2" v-if="viewDataArray.date">
                                                    <b>Edad: </b>{{ getEdad(viewDataArray.date) }} años
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="text-justify m-2">
                                                    <b>Email:</b> {{ viewDataArray.email }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="text-justify m-2">
                                                    <b>Celular:</b> {{ viewDataArray.phone }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: flex; justify-content: left; align-items: center;">
                                        <div class="d-flex flex-row">
                                            <div class="row">
                                                <div class="text-justify m-2">
                                                    <b>Fecha de Nacimiento:</b> {{
                                            moment(viewDataArray.date).format('DD/MM/YYYY')
                                        }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="text-justify m-2">
                                                    <b>Género:</b> {{ viewDataArray.gender }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="text-justify m-2">
                                                    <b>Pais:</b> {{ viewDataArray.country }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="text-justify m-2">
                                                    <b>Estado:</b> {{ viewDataArray.estadoRepublica }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: flex; justify-content: left; align-items: center;">
                                        <div class=" row">
                                            <div class="text-justify m-2">
                                                <b>Municipio/Delegación:</b> {{ viewDataArray.municipaly }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="text-justify m-2">
                                                <b>Colonia:</b> {{ viewDataArray.delegation }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Información Escolar -->
                            <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel"
                                aria-labelledby="profile-tab" tabindex="0">
                                <div class="container-postulante flex mt-3">
                                    <div style="display: flex; flex-direction: column;">
                                        <div style="display: flex; justify-content: left; align-items: center;"
                                            v-show="viewDataArray.status != 'exuniversitario'">
                                            <div class="row">
                                                <div class="text-justify m-2"><b>Escuela:</b>
                                                    {{ viewDataArray.school }}
                                                </div>
                                            </div>
                                            <div class="row" v-show="viewDataArray.status != 'exuniversitario'">
                                                <div class="text-justify m-2"><b>Plantel:</b>
                                                    {{ viewDataArray.plantel }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="text-justify m-2"><b>Promedio:</b>
                                                    {{ viewDataArray.promedio }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: flex; justify-content: left; align-items: center;"
                                        v-show="viewDataArray.status != 'exuniversitario'">
                                        <div class="row">
                                            <div class="text-justify m-2"><b>Carrera:</b> {{ viewDataArray.career }}
                                            </div>
                                        </div>
                                        <div class="row" v-show="viewDataArray.status == 'preuniversitario'">
                                            <div class="text-justify m-2"><b>Tipo de Bachillerato:</b>
                                                {{ viewDataArray.typePreparatory }}</div>
                                        </div>
                                    </div>
                                    <div v-if="viewDataArray.status == 'universitario'"
                                        style="display: flex; flex-direction: row;">
                                        <div class="row">
                                            <!-- Años cursados -->
                                            <div class="text-justify m-2" v-if="viewDataArray.yearActual == 'MedioAño'">
                                                <b>Años
                                                    Cursados: </b> Medio Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'UnAño'">
                                                <b>Años
                                                    Cursados: </b> 1 Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'AñoyMedio'">
                                                <b>Años
                                                    Cursados: </b> 1 Año y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'DosAños'">
                                                <b>Años
                                                    Cursados: </b> 2 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'DosAñosyMedio'">
                                                <b>Años Cursados: </b> 2 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Tres Años'">
                                                <b>Años
                                                    Cursados: </b> 3 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Tres Años y Medio'">
                                                <b>Años Cursados: </b> 3 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Cuatro'">
                                                <b>Años
                                                    Cursados: </b> 4 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Cuatro Años y Medio'">
                                                <b>Años
                                                    Cursados:
                                                </b> 4
                                                Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Cinco'">
                                                <b>Años
                                                    Cursados: </b> 5 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'CincoAñosyMedio'">
                                                <b>Años Cursados: </b> 5 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'SeisAños'">
                                                <b>Años
                                                    Cursados: </b> 6 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'SeisAñosyMedio'">
                                                <b>Años Cursados: </b> 6 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'SieteAños'">
                                                <b>Años
                                                    Cursados: </b> 7 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'SieteAñosyMedio'">
                                                <b>Años Cursados: </b> 7 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == '+OchoAños'">
                                                <b>Años Cursados: </b> Más de 8 años
                                            </div>
                                        </div>
                                        <div class="row">
                                            <!-- Duración de la carrera -->
                                            <div class="text-justify m-2" v-if="viewDataArray.yearTotal == 'MedioAño'">
                                                <b>Duración de la carrera: </b> Medio Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'UnAño'">
                                                <b>Duración de la carrera: </b> 1 Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'AñoyMedio'">
                                                <b>Duración de la carrera: </b> 1 Año y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'DosAños'">
                                                <b>Duración
                                                    de la carrera: </b> 2 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'DosAñosyMedio'">
                                                <b>Duración de la carrera: </b> 2 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'Tres Años'">
                                                <b>Duración de la carrera: </b> 3 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'Tres Años y Medio'">
                                                <b>Duración de la carrera: </b> 3 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'Cuatro'">
                                                <b>Duración
                                                    de la carrera: </b> 4 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'Cuatro Años y Medio'">
                                                <b>Duración de la carrera: </b> 4 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'Cinco'">
                                                <b>Duración de
                                                    la carrera: </b> 5 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'CincoAñosyMedio'">
                                                <b>Duración de la carrera: </b> 5 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'SeisAños'">
                                                <b>Duración
                                                    de la carrera: </b> 6 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'SeisAñosyMedio'">
                                                <b>Duración de la carrera: </b> 6 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'SieteAños'">
                                                <b>Duración de la carrera: </b> 7 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'SieteAñosyMedio'">
                                                <b>Duración de la carrera: </b> 7 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == '+OchoAños'">
                                                <b>Duración de la carrera: </b> Más de 8 años
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="viewDataArray.status == 'preuniversitario'"
                                        style="display: flex; flex-direction: row;">
                                        <div class="row">
                                            <!-- Años cursados -->
                                            <div class="text-justify m-2"
                                                v-if="viewDataArray.yearActual == 'Medio Año'">
                                                <b>Años Cursados:</b> Medio Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Un Año'">
                                                <b>Años Cursados: </b> 1 Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Año y Medio'">
                                                <b>Años Cursados:</b> 1 Año y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Dos Años'">
                                                <b>Años
                                                    Cursados:</b> 2 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Dos Años y Medio'">
                                                <b>Años Cursados:</b> 2 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Tres Años'">
                                                <b>Años
                                                    Cursados:</b> 3 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Tres Años y Medio'">
                                                <b>Años Cursados:</b> 3 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Cuatro'">
                                                <b>Años
                                                    Cursados:</b> 4 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Cuatro Años y Medio'">
                                                <b>Años
                                                    Cursados:
                                                </b> 4
                                                Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Cinco'">
                                                <b>Años
                                                    Cursados: </b> 5 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Cinco Años y Medio'">
                                                <b>Años Cursados: </b> 5 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Seis Años'">
                                                <b>Años
                                                    Cursados: </b> 6 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Seis Años y Medio'">
                                                <b>Años Cursados: </b> 6 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Siete Años'">
                                                <b>Años
                                                    Cursados: </b> 7 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == 'Siete Años y Medio'">
                                                <b>Años Cursados: </b> 7 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearActual == '+Ocho Años'">
                                                <b>Años
                                                    Cursados: </b> Más de 8 años
                                            </div>

                                        </div>
                                        <div class="row">
                                            <!-- Duración de la carrera -->
                                            <div class="text-justify m-2" v-if="viewDataArray.yearTotal == 'MedioAño'">
                                                <b>Duración
                                                    de
                                                    bachillerato: </b> Medio Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'UnAño'">
                                                <b>Duración de
                                                    bachillerato: </b> 1 Año
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'AñoyMedio'">
                                                <b>Duración de bachillerato: </b> 1 Año y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'DosAños'">
                                                <b>Duración
                                                    de bachillerato: </b> 2 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'DosAñosyMedio'">
                                                <b>Duración de bachillerato: </b> 2 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'Tres Años'">
                                                <b>Duración de bachillerato: </b> 3 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'Tres Años y Medio'">
                                                <b>Duración de bachillerato: </b> 3 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'Cuatro'">
                                                <b>Duración
                                                    de bachillerato: </b> 4 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'Cuatro Años y Medio'">
                                                <b>Duración de bachillerato: </b> 4 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'Cinco'">
                                                <b>Duración de
                                                    bachillerato: </b> 5 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'CincoAñosyMedio'">
                                                <b>Duración de bachillerato: </b> 5 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'SeisAños'">
                                                <b>Duración
                                                    de bachillerato: </b> 6 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'SeisAñosyMedio'">
                                                <b>Duración de bachillerato: </b> 6 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'SieteAños'">
                                                <b>Duración de bachillerato: </b> 7 Años
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == 'SieteAñosyMedio'">
                                                <b>Duración de bachillerato: </b> 7 Años y medio
                                            </div>
                                            <div class="text-justify m-2"
                                                v-else-if="viewDataArray.yearTotal == '+OchoAños'">
                                                <b>Duración de bachillerato: </b> Más de 8 años
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Formulario Ex-Universitario-->
                                    <div v-if="viewDataArray.status == 'exuniversitario'">
                                        <div style="display: flex; flex-direction: row;">
                                            <div class="row">
                                                <div class="text-justify m-2" v-if="viewDataArray.schoolLic">
                                                    <b>Escuela de Licenciatura: </b> {{ viewDataArray.schoolLic }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="text-justify m-2" v-if="viewDataArray.careerLic">
                                                    <b>Nombre de la carrera: </b> {{ viewDataArray.careerLic }}
                                                </div>
                                            </div>
                                        </div>
                                        <div style="display: flex; flex-direction: row;">
                                            <div class="row">
                                                <div class="text-justify m-2" v-if="viewDataArray.startDateLic">
                                                    <b>Inicio
                                                        de
                                                        la
                                                        carrera: </b> {{ viewDataArray.startDateLic }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="text-justify m-2" v-if="viewDataArray.endDateLic">
                                                    <b>Termino
                                                        de la
                                                        carrera: </b> {{ viewDataArray.endDateLic }}
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Maestría -->
                                        <div style="display: flex; flex-direction: row;">
                                            <div class="row" v-show="viewDataArray.schoolMa">
                                                <div class="text-justify m-2" v-if="viewDataArray.schoolMa"><b>Escuela
                                                        de
                                                        Licenciatura: </b> {{ viewDataArray.schoolMa }} </div>
                                            </div>
                                            <div class="row" v-show="viewDataArray.schoolMa">
                                                <div class="text-justify m-2" v-if="viewDataArray.careerMa"><b>Nombre de
                                                        la
                                                        carrera: </b> {{ viewDataArray.careerMa }} </div>
                                            </div>
                                        </div>
                                        <div style="display: flex; flex-direction: row;">
                                            <div class="row" v-show="viewDataArray.schoolMa">
                                                <div class="text-justify m-2" v-if="viewDataArray.startDateMa">
                                                    <b>Inicio
                                                        de la
                                                        carrera: </b> {{ viewDataArray.startDateMa }}
                                                </div>
                                            </div>
                                            <div class="row" v-show="viewDataArray.schoolMa">
                                                <div class="text-justify m-2" v-if="viewDataArray.endDateMa"> <b>Termino
                                                        de
                                                        la
                                                        carrera: </b> {{ viewDataArray.endDateMa }}</div>
                                            </div>
                                        </div>
                                        <!-- Doctorado -->
                                        <div style="display: flex; flex-direction: row;">
                                            <div class="row" v-show="viewDataArray.schoolDoc">
                                                <div class="text-justify m-2" v-if="viewDataArray.schoolDoc"><b>Escuela
                                                        de
                                                        Licenciatura: </b> {{ viewDataArray.schoolDoc }} </div>
                                            </div>
                                            <div class="row" v-show="viewDataArray.schoolDoc">
                                                <div class="text-justify m-2" v-if="viewDataArray.careerDoc"><b>Nombre
                                                        de
                                                        la
                                                        carrera: </b> {{ viewDataArray.careerDoc }} </div>
                                            </div>
                                        </div>
                                        <div style="display: flex; flex-direction: row;">
                                            <div class="row" v-show="viewDataArray.schoolDoc">
                                                <div class="text-justify m-2" v-if="viewDataArray.startDateDoc">
                                                    <b>Inicio
                                                        de
                                                        la
                                                        carrera: </b> {{ viewDataArray.startDateDoc }}
                                                </div>
                                            </div>
                                            <div class="row" v-show="viewDataArray.schoolDoc">
                                                <div class="text-justify m-2" v-if="viewDataArray.endDateDoc">
                                                    <b>Termino
                                                        de la
                                                        carrera: </b> {{ viewDataArray.endDateDoc }}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <!-- Idiomas -->
                            <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel"
                                aria-labelledby="contact-tab" tabindex="0">
                                <div class="container-postulante flex mt-3">
                                    <div class="row" v-for="(exp, i) in viewDataArray.idiomas" :key="i + 1">
                                        <div class="col-4 text-justify m-2"> <b>Idioma {{ i + 1 }}</b> {{ exp.idioma }}
                                        </div>
                                        <div class="col-4 text-justify m-2"> <b>Nivel:</b> {{ exp.nivel }} </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Hobbies -->
                            <div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel"
                                aria-labelledby="disabled-tab" tabindex="0">
                                <div class="container-postulante flex mt-3" style="margin-left: 15px;">
                                    <div class="row mb-2" v-for="(exp, i) in viewDataArray.hobbies" :key="i + 1">
                                        <div class="col text-justify"> <b>Hobbie: </b> {{ exp.name }}</div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col text-justify" v-if="viewDataArray.hobbieWrite1"> <b>Hobbie:</b>
                                            {{ viewDataArray.hobbieWrite1 }}
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col text-justify" v-if="viewDataArray.hobbieWrite2"> <b>Hobbie:</b>
                                            {{ viewDataArray.hobbieWrite2 }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Experiencia -->
                            <div class="tab-pane fade" id="experiencia-tab-pane" role="tabpanel"
                                aria-labelledby="experiencia-tab" tabindex="0">
                                <div class="container-postulante flex">
                                    <div v-for="(exp, i) in viewDataArray.experiencia" :key="i + 1">
                                        <div v-if="exp.experienciaCompany"
                                            style="display: flex; flex-direction: row; justify-content: left; align-items: left; margin-top: 10px;">
                                            <div class="row">
                                                <div class="text-justify m-2"> <b>Nombre de la Empresa:</b>
                                                    {{ exp.experienciaCompany }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="text-justify m-2"> <b>Puesto:</b>
                                                    {{ exp.experienciaPuesto }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="exp.experienciaCompany"
                                            style="display: flex; flex-direction: row; justify-content: left; align-items: left;">
                                            <div class="row">
                                                <div class="text-justify m-2"> <b>Salario: </b>
                                                    ${{ exp.experienciaSalario }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="text-justify m-2"><b>Fecha de Inicio:</b>
                                                    {{ exp.experienciaFechaInicio }}
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div v-if="exp.experienciaFechaFin" class="text-justify m-2"><b>Fecha de
                                                        Termino:</b>
                                                    {{ exp.experienciaFechaFin }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="row mt-3 mb-2" style="margin-left: 10px;">
                                            <span class="text-center">Sin Experiencia Previa</span>
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="aceptUser()">
                            Aceptar Usuario
                        </button> -->
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteUser()">
                            Borrar Usuario
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="card-title mt-5">
                                                                                                                                                                                <h3 class="card-label text-dark">Admin > Historial de Registros</h3>
                                                                                                                                                                         </div> -->
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
            <div class="btn-group mb-2" id="btnradio" role="group" aria-label="Basic radio toggle button group"
                style="display: flex; justify-content: center;">
                <div style="margin-right: 10px;">
                    <input type="radio" v-on:click="filters()" class="btn-check" name="btnradio" id="btnradio1"
                        :value="estadoPendiente">
                    <label class="btn btn-outline-danger" for="btnradio1">Registros Pendientes</label>
                </div>
                <div>
                    <input type="radio" v-on:click="filters()" class="btn-check" checked name="btnradio" id="btnradio2"
                        :value="estadoTodo">
                    <label class="btn btn-outline-primary" for="btnradio2">Todos Los Registros</label>
                </div>
                <div style="margin-left: 10px;">
                    <input type="radio" v-on:click="filters()" class="btn-check" name="btnradio" id="btnradio3"
                        :value="estadoAceptado">
                    <label class="btn btn-outline-success" for="btnradio3">Registros Aceptados</label>
                </div>
            </div>
            <div>
                <div style="margin-left: 10px; margin-bottom: 8px;">
                    <button type="button" class="btn btn-warning" v-on:click="refreshList()"
                        style="display: flex; justify-content: center; align-items: center;">
                        <box-icon name='loader-alt' color="white" animation='spin'></box-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="card card-custom gutter-b">
            <div class="card-header bg-success pb-3 pt-3">
                <div class="form-group row">
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-6">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table class="table align-middle table-responsive table-hover caption-top" style="font-family: Poppins">
                <thead>
                    <tr align="center">
                        <th scope="col">Nombre</th>
                        <th scope="col">Correo Electronico</th>
                        <th scope="col">Usuario</th>
                        <th scope="col">Fecha de Registro</th>
                        <th scope="col">Estatus Estudiante</th>
                        <th scope="col">Documentos</th>
                        <th scope="col">Opciones</th>
                    </tr>

                </thead>
                <tbody v-if="response.loading">
                    <tr>
                        <td colspan="7">
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border text-danger" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else v-for="user in dataPage" v-bind:key="user">
                    <tr align="center">
                        <td style="width:18%">
                            {{ user.name }}
                            {{ user.lastname }}
                            {{ user.secondlastname }}
                        </td>
                        <td style="width:5%">{{ user.informationStudent.email }}</td>
                        <td style="text-transform: capitalize">{{ user.status }} </td>
                        <td> {{ moment(user.informationStudent.createdAt).format("DD/MM/YYYY") }} </td>
                        <td>
                            <p class="badge"
                                :class="{ 'bg-danger': !user.informationStudent.status, 'bg-success': user.informationStudent.status, }">
                                {{ user.informationStudent.status ? "Aceptado" : "Pendiente" }}
                            </p>
                        </td>
                        <td style="display: flex; flex-direction: row; justify-content: center; align-content: center;">
                            <button type="button" class="btn btn-icon btn- btn-hover-warning btn-sm" title="Ver CV">
                                <a :href="user.filecomprobantes?.link" target="_blank" class="btn btn-primary">
                                    <box-icon type='solid' color="white" name='file-pdf'></box-icon>
                                </a>
                            </button>
                            <button type="button" class="btn btn-icon btn-hover-warning btn-sm"
                                title="Ver CV">
                                <a :href="user.filecv?.link" target="_blank" class="btn btn-primary">
                                    <box-icon type='solid' color="white" name='file-pdf'></box-icon>
                                </a>
                            </button>
                        </td>
                        <td align="center">
                            <button type="button" class="btn btn-icon btn-success btn-hover-primary btn-sm mx-3"
                                data-bs-toggle="modal" data-bs-target="#miModal" @click="viewData(user)"
                                title="Ver Usuario">
                                <box-icon type='solid' color="white" name='show'></box-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- Paginador -->
            <nav aria-label="Page navigation example" v-show="listStudents.length > 0">
                <ul class="pagination justify-content-center">
                    <li v-if="this.actual > 1" class="page-item" v-on:click="getPreviousPage()">
                        <a class="page-link" href="#">Anterior</a>
                    </li>
                    <li v-for="page in totalPagesNumber" v-bind:key="page" v-on:click="getDataPages(page)"
                        v-bind:class="isActive(page)" class="page-item">
                        <a v-if="page < this.actual + 7 && page > this.actual - 7" class="page-link" href="#">{{ page
                            }}</a>
                    </li>
                    <li class="page-item" v-on:click="getNextPage()">
                        <a class="page-link" href="#">Siguiente</a>
                    </li>
                </ul>
            </nav>
        </div>
    </main>
</template>

<script>
import { AdminService } from "@/services";
import moment from "moment";
moment.locale("es");

export default {
    data: () => ({
        moment: moment,
        elementPage: 20,
        dataPage: [],
        data: {},
        listStudents: {},
        listStudentsTemp: {},
        viewDataArray: {},
        actual: 1,
        estadoPendiente: "Pendiente",
        estadoTodo: "Todo",
        estadoAceptado: "Aceptado",
        response: {},
        totalPagesNumber: 0,
    }),
    created() {
        this.students();
    },
    methods: {
        async students() {
            try {
                this.response.loading = true;
                await AdminService.getStudent({ search: "" }).then((res) => {
                    this.listStudents = res.data.data;
                    this.listStudentsTemp = res.data.data;
                    this.totalPages(this.listStudents.length)
                    this.listStudents.length > 0 ? this.getDataPages(1) : this.response.loading = true;
                    this.response.loading = false;
                });
                this.response.loading = false;
            } catch (error) {
                this.response.loading = false;
            }
        },
        async aceptUser() {
            let data = { id: this.id };
            await AdminService.aceptUser(data).then((res) => {
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            }).catch((e) => {
                this.$swal({
                    position: "top-end",
                    icon: "error",
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            });
        },
        deleteUser() {
            AdminService.deleteUser({ id: this.id })
                .then((res) => {
                    this.$swal({
                        position: "top-center",
                        icon: "success",
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                })
                .catch((e) => {
                    this.$swal({
                        position: "top-end",
                        icon: "error",
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                });
        },
        viewData(user) {
            this.viewDataArray = user;
            this.id = user._id;
            let myModal = new window.bootstrap.Modal('#modalUser', { keyboard: false })
            myModal.show();
        },
        async totalPages(data) {

            this.totalPagesNumber = Math.ceil(data / 20);

        },
        getDataPages(numberPage) {
            this.actual = numberPage;
            this.dataPage = [];
            let start = numberPage * this.elementPage - this.elementPage;
            let end = numberPage * this.elementPage;
            this.dataPage = this.listStudents.slice(start, end);
        },
        getPreviousPage() {
            if (this.actual > 1) {
                this.actual--;
            }
            this.getDataPages(this.actual);
        },
        getNextPage() {
            if (this.actual < this.totalPages()) {
                this.actual++;
            }
            this.getDataPages(this.actual);
        },
        isActive(numberPage) {
            return numberPage === this.actual ? "active" : "";
        },
        //TODO: Calcular Edad De Postulantes
        getEdad(dateString) {
            let hoy = new Date()
            let fechaNacimiento = new Date(dateString)
            let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
            let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
            if (
                diferenciaMeses < 0 ||
                (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
            ) {
                edad--
            }
            return edad
        },
        openLinkedin(url) {
            let word = "https://"
            url.includes(word) ? window.open(url) : window.open("https://" + url);
        },
        refreshList() {
            this.students();
        },
        filters() {
            let opciones = document.querySelectorAll('input[type="radio"]');
            document.querySelector('#btnradio').addEventListener('change', () => {
                opciones.forEach(opcion => {
                    if (opcion.checked) {
                        if (opcion.value === "Pendiente") {
                            let estudiantesfilter = this.listStudentsTemp.filter(estudiante => {
                                return estudiante.informationStudent.status === false;
                            });
                            this.listStudents = estudiantesfilter;
                            let start = this.actual * this.elementPage - this.elementPage;
                            let end = this.actual * this.elementPage;
                            this.dataPage = this.listStudents.slice(start, end);
                        } else if (opcion.value === "Aceptado") {
                            let estudiantesfilter = this.listStudentsTemp.filter(estudiante => {
                                return estudiante.informationStudent.status === true;
                            });
                            this.listStudents = estudiantesfilter;
                            let start = this.actual * this.elementPage - this.elementPage;
                            let end = this.actual * this.elementPage;
                            this.dataPage = this.listStudents.slice(start, end);
                        } else {
                            this.listStudents = this.listStudentsTemp;
                            let start = this.actual * this.elementPage - this.elementPage;
                            let end = this.actual * this.elementPage;
                            this.dataPage = this.listStudents.slice(start, end);
                        }
                    }
                })
            })
        },
    },
};
</script>
